import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import sort_img from './img/sort.svg';
import edit_img from "./img/edit.png";

function Categories() {
    const [categories, setCategories] = useState([]);
    const [categoriesLoaded, setCategoriesLoaded] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupEdit, setIsPopupEdit] = useState(false);
    const [editId, setEditId] = useState(false);
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');

    const openPopup = () => {
        setIsPopupOpen(true);
        setIsPopupEdit(false);
        setPrice('');
    };

    // Функция для закрытия попапа
    const closePopup = () => {
        setIsPopupOpen(false);
    };

    useEffect(() => {
        axios.get('/api/categories', {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }})
            .then(response => {
                setCategories(response.data);
                setCategoriesLoaded(true);
            })
            .catch(error => console.error(error));
    }, []);

    const removeCategory = (categoryId) => {
        if (window.confirm(`Вы уверены, что хотите удалить категорию ${categoryId}?`)) {
            axios.delete(`/api/category/${categoryId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(() => {
                    setCategories(categories.filter(item => item.id !== categoryId));
                })
                .catch(error => console.error(error));
        }
    };

    const handleOverlayClick = (e) => {
        // Проверяем, что клик произошел именно по фону, а не по содержимому попапа
        if (e.target === e.currentTarget) {
            closePopup();
        }
    };

    const renderHeader = (key, label) => (
        <th>
            {label}
        </th>
    );

    const sendCategory = (e) => {
        e.preventDefault();
        if (isPopupEdit) {
            const formData = new FormData();
            formData.append('title', category);

            axios.put(`/api/category/${editId}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(response => {
                    alert('Категория успешно обновлена!');
                    setIsPopupOpen(false);
                    setCategory('');
                    setDescription('');
                    setPrice('');
                    axios.get('/api/categories', {headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }})
                        .then(response => setCategories(response.data))
                        .catch(error => console.error(error));
                })
                .catch(error => {
                    console.error(error);
                    alert('Ошибка обновления категории');
                });
        } else {
            const formData = new FormData();
            formData.append('title', category);

            axios.post('/api/categories', formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(response => {
                    alert('Категория успешно добавлена!');
                    setCategories([...categories, response.data.category]);
                    setIsPopupOpen(false);
                    setCategory('');
                    setDescription('');
                    setPrice('');
                })
                .catch(error => {
                    console.error(error);
                    alert('Ошибка добавления категории');
                });
        }
    };

    const editCategory = (categoryId) => {
        const subcategoryEdit = categories.find(item => item.id === categoryId);
        setIsPopupOpen(true);
        setIsPopupEdit(true);
        setCategory(subcategoryEdit.title);
        setEditId(subcategoryEdit.id)
    };

    const renderCategories = (categories) => {
        if (categories.length > 0) {
            return (
                <tbody>
                {categories.map(category => (
                    <tr key={category.id}>
                        <td>{category.id}</td>
                        <td>{category.title}</td>
                        <td style={{textAlign: "center"}}>
                            <span className="action-icon" onClick={() => editCategory(category.id)}>
                                <span className="icon">
                                    <svg className="feather feather-edit" fill="none" height="24"
                                         stroke="currentColor" strokeLinecap="round"
                                         strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                         width="24" xmlns="http://www.w3.org/2000/svg"><path
                                        d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path
                                        d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>
                                </span>
                            </span>
                            <span className="action-icon" onClick={() => removeCategory(category.id)}><span
                                className="icon">×</span></span>
                        </td>
                    </tr>
                ))}
                </tbody>
            )
        } else {
            return <tbody>
            <tr>
                <td colSpan={100} style={{textAlign: "center"}}>
                    {categoriesLoaded ? 'Список категорий пуст' : 'Загрузка...'}
                </td>
            </tr>
            </tbody>
        }
    }

    return (
        <div>
            <div className="head">
                <div><h1>Категории</h1></div>
                <div className="head-buttons">
                    <button onClick={openPopup}>
                        Добавить
                    </button>
                </div>
            </div>

            <div className="border-table">
                <table>
                    <thead>
                    <tr>
                        {renderHeader('id', 'ID')}
                        {renderHeader('category', 'Категория')}
                        <th>Действия</th>
                    </tr>
                    </thead>
                    {renderCategories(categories)}
                </table>
            </div>
            <div>
                {isPopupOpen && (
                    <div className="overlay" onClick={handleOverlayClick}>
                        <div className="popup">
                            <div className="crest" onClick={closePopup}>×</div>
                            <h2>{isPopupEdit && (<span>Редактировать</span>) || (
                                <span>Добавить категорию</span>)}</h2>
                            <form className="addProduct" onSubmit={sendCategory}>
                                <div>
                                    <label htmlFor="">Категория</label>
                                    <input
                                        type="text"
                                        value={category}
                                        placeholder="Категория"
                                        onChange={(e) => setCategory(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="button-block">
                                    {isPopupEdit && (<button type="submit">Редактировать</button>) || (
                                        <button type="submit">Добавить</button>)}
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Categories;