import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import sort_img from './img/sort.svg';
import edit_img from './img/edit.png';

function Products() {
    const [products, setProducts] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [productsLoaded, setProductsLoaded] = useState(false);
    const [categoriesLoaded, setCategoriesLoaded] = useState(false);
    const [subcategoriesLoaded, setSubcategoriesLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupEdit, setIsPopupEdit] = useState(false);
    const [editId, setEditId] = useState(false);
    const [category, setCategory] = useState(0);
    const [subcategory, setSubcategory] = useState([]);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [twoFactor, setTwoFactor] = useState('');
    const [backup, setBackup] = useState('');
    const [altEmail, setAltEmail] = useState('');
    const [passwordAltEmail, setPasswordAltEmail] = useState('');
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        console.log("Selected file:", selectedFile.name);
    };

    const openPopup = () => {
        setIsPopupOpen(true);
        setIsPopupEdit(false);
        setCategory(categories[0].id);
        setLogin('');
        setPassword('');
        setTwoFactor('');
        setBackup('');
        setAltEmail('');
        setPasswordAltEmail('');

        const subcategoriesToShow = subcategories.filter(subc => subc.category == categories[0].id);
        console.log(subcategoriesToShow);
        setSubcategory(subcategoriesToShow[0].id);
    };

    // Функция для закрытия попапа
    const closePopup = () => {
        setIsPopupOpen(false);
    };

    useEffect(() => {
        axios.get('/api/categories', {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }})
            .then(response => {
                setCategories(response.data);
                setCategoriesLoaded(true)
            })
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        axios.get('/api/subcategories', {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }})
            .then(response => {
                setSubcategories(response.data);
                setSubcategoriesLoaded(true)
            })
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        axios.get('/api/products', {headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }})
            .then(response => {
                setProducts(response.data);
                setProductsLoaded(true)
            })
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        if (setProductsLoaded && subcategoriesLoaded && categoriesLoaded) {
            const subcategoriesToShow = subcategories.filter(subc => subc.category == category);
            console.log(subcategoriesToShow[0].id)
            setSubcategory(subcategoriesToShow[0].id);
        }
    }, [category]);

    const removeProduct = (productId) => {
        if (window.confirm(`Вы уверены, что хотите удалить продукт ${productId}?`)) {
            axios.delete(`/api/product/${productId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(() => {
                    setProducts(products.filter(item => item.id !== productId));
                })
                .catch(error => console.error(error));
            axios.get('/api/products', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
                .then(response => setProducts(response.data))
                .catch(error => console.error(error));
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedProducts = [...products].sort((a, b) => {
        if (sortConfig.key) {
            const order = sortConfig.direction === 'asc' ? 1 : -1;
            return a[sortConfig.key] > b[sortConfig.key] ? order : -order;
        }
        return 0;
    });

    const handleOverlayClick = (e) => {
        // Проверяем, что клик произошел именно по фону, а не по содержимому попапа
        if (e.target === e.currentTarget) {
            closePopup();
        }
    };

    const renderHeader = (key, label) => (
        <th onClick={() => handleSort(key)}>
            {label}
            {sortConfig.key === key && <img src={sort_img} alt="Sort icon" style={{ marginLeft: 5, width: 10, height: 10 }} />}
        </th>
    );

    const renderSubcategoriesSelect = () => {
        var subcategoriesToShow = [];
        subcategoriesToShow = subcategories.filter(subc => subc.category == category);
        return (<div>
            <label htmlFor="">Подкатегория</label>
            <select required value={subcategory}
                    onChange={e => setSubcategory(e.target.value)}>
                <option disabled>Подкатегория</option>
                {subcategoriesToShow.map((subcat, index) => (
                    <option key={index} value={subcat.id}>
                        {subcat.title}
                    </option>
                ))}
            </select>
        </div>);
    };

    const changeCategory = (e) => {
        setCategory(e.target.value)
        var subcategoriesToShow = [];
        subcategoriesToShow = subcategories.filter(subc => subc.category == e.target.value);
        setSubcategory(subcategoriesToShow[0].id);
    };

    const sendProduct = (e) => {
        e.preventDefault();
        if (isPopupEdit) {
            const formData = new FormData();
            formData.append('category', category);
            formData.append('subcategory', subcategory);
            formData.append('login', login);
            formData.append('password', password);
            formData.append('twoFactor', twoFactor);
            formData.append('backup', backup);
            formData.append('altEmail', altEmail);
            formData.append('passwordAltEmail', passwordAltEmail);
            formData.append('file', file);

            axios.put(`/api/product/${editId}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    alert('Product edited successfully!');
                    setIsPopupOpen(false);
                    setLogin('');
                    setPassword('');
                    setTwoFactor('');
                    setBackup('');
                    setAltEmail('');
                    setPasswordAltEmail('');
                    fileInputRef.current.value = '';
                    axios.get('/api/products', {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                        .then(response => setProducts(response.data))
                        .catch(error => console.error(error));
                })
                .catch(error => {
                    console.error(error);
                    alert('Error editing product');
                });
        } else {
            const formData = new FormData();
            formData.append('category', category);
            formData.append('subcategory', subcategory);
            formData.append('login', login);
            formData.append('password', password);
            formData.append('twoFactor', twoFactor);
            formData.append('backup', backup);
            formData.append('altEmail', altEmail);
            formData.append('passwordAltEmail', passwordAltEmail);
            formData.append('file', file);

            axios.post('/api/products', formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    alert('Product added successfully!');
                    setProducts([...products, response.data.product]);
                    setIsPopupOpen(false);
                    setLogin('');
                    setPassword('');
                    setTwoFactor('');
                    setBackup('');
                    setAltEmail('');
                    setPasswordAltEmail('');
                    fileInputRef.current.value = '';
                })
                .catch(error => {
                    console.error(error);
                    alert('Error adding product');
                });
        }
    };

    const editProduct = (productId) => {
        const productEdit = products.find(item => item.id === productId);
        setIsPopupOpen(true);
        setIsPopupEdit(true);
        setLogin(productEdit.login);
        setCategory(productEdit.category);
        setSubcategory(productEdit.subcategory);
        setPassword(productEdit.password);
        setTwoFactor(productEdit.twoFactor);
        setBackup(productEdit.backup);
        setAltEmail(productEdit.altEmail);
        setPasswordAltEmail(productEdit.passwordAltEmail);
        setEditId(productId)
    };

    const renderCategoriesSelect = () => {
        return (<div>
            <label htmlFor="">Категория</label>
            <select required value={category}
                    onChange={e => setCategory(e.target.value)}>
                <option disabled>Категория</option>
                {categories.map((category, index) => (
                    <option key={index} value={category.id}>
                        {category.title}
                    </option>
                ))}
            </select>
        </div>);
    };

    const renderProducts = (products) => {
        if (products != null) {
            if (products.length > 0) {
                return (
                    <tbody>
                    {products.map(product => (
                        <tr key={product.id}>
                            <td>{product.id}</td>
                            <td>{categories.filter(category => category.id == product.category)[0]?.title || ""}</td>
                            <td>{subcategories.filter(subc => subc.id == product.subcategory)[0]?.title || ""}</td>
                            <td>{product.login}</td>
                            <td>{product.password}</td>
                            <td>{product.twoFactor}</td>
                            <td>{product.backup}</td>
                            <td>{product.altEmail}</td>
                            <td>{product.passwordAltEmail}</td>
                            <td>{product.sold ? "Да" : "Нет"}</td>
                            <td style={{textAlign: "center"}}>
                            <span className="action-icon" onClick={() => editProduct(product.id)}>
                                <span className="icon">
                                    <svg className="feather feather-edit" fill="none" height="24"
                                         stroke="currentColor" strokeLinecap="round"
                                         strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                         width="24" xmlns="http://www.w3.org/2000/svg"><path
                                        d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path
                                        d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>
                                </span>
                            </span>
                                <span className="action-icon" onClick={() => removeProduct(product.id)}><span
                                    className="icon">×</span></span>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                )
            } else {
                return <tbody>
                <tr>
                    <td colSpan={100} style={{textAlign: "center"}}>
                        {productsLoaded ? 'Список продуктов пуст' : 'Загрузка...'}
                    </td>
                </tr>
                </tbody>
            }
        }
    }

    return (
        <div>
            <div className="head">
                <div><h1>Офферы</h1></div>
                <div className="head-buttons">
                    <button onClick={openPopup}>
                        Добавить
                    </button>
                </div>
            </div>

            <div className="border-table">
                <table>
                    <thead>
                    <tr>
                        {renderHeader('id', 'ID')}
                        {renderHeader('category', 'Категория')}
                        {renderHeader('subcategory', 'Подкатегория')}
                                {renderHeader('login', 'Логин')}
                                {renderHeader('password', 'Пароль')}
                                {renderHeader('twoFactor', 'Двух-фактор')}
                                {renderHeader('backup', 'Коды бекапа')}
                                {renderHeader('altEmail', 'Альтернативная почта')}
                                {renderHeader('passwordAltEmail', 'Пароль альтернативной почты')}
                                {renderHeader('sold', 'Продан')}
                                <th>Действия</th>
                            </tr>
                            </thead>
                            {renderProducts(sortedProducts)}
                        </table>
                    </div>
                        <div>
                            {isPopupOpen && (
                                <div className="overlay" onClick={handleOverlayClick}>
                                    <div className="popup">
                                        <div className="crest" onClick={closePopup}>×</div>
                                        <h2>{isPopupEdit && (<span>Редактировать</span>) || (
                                            <span>Добавить оффер</span>)}</h2>
                                        <form className="addProduct" onSubmit={sendProduct}>
                                            {renderCategoriesSelect()}
                                            {renderSubcategoriesSelect()}
                                            <div>
                                                <label htmlFor="">Логин</label>
                                                <input
                                                    type="text"
                                                    value={login}
                                                    placeholder="Логин"
                                                    onChange={(e) => setLogin(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="">Пароль</label>
                                                <input
                                                    type="text"
                                                    value={password}
                                                    placeholder="Пароль"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="">Двухфактор</label>
                                                <input
                                                    type="text"
                                                    value={twoFactor}
                                                    placeholder="Двухфактор"
                                                    onChange={(e) => setTwoFactor(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="">Бекап коды</label>
                                                <input
                                                    type="text"
                                                    value={backup}
                                                    placeholder="Бекап коды"
                                                    onChange={(e) => setBackup(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="">Альтернативная почта</label>
                                                <input
                                                    type="text"
                                                    value={altEmail}
                                                    placeholder="Альтернативная почта"
                                                    onChange={(e) => setAltEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="">Пароль альтернативной почты</label>
                                                <input
                                                    type="text"
                                                    value={passwordAltEmail}
                                                    placeholder="Пароль альтернативной почты"
                                                    onChange={(e) => setPasswordAltEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="">Файл cookie</label>
                                                <input type="file" required onChange={handleFileChange}
                                                       ref={fileInputRef}/>
                                            </div>
                                            <div className="button-block">
                                                {isPopupEdit && (<button type="submit">Редактировать</button>) || (
                                                    <button type="submit">Добавить</button>)}

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                    );
                    }

                    export default Products;